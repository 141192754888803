/**
 * @file Handles all configurations for the homepage tools.
 * @author Daniel Rittrich
 */

import { getStyleQuery } from '../../utils/tools';
import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';

const configThomasExclusiveAstonMartin: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 53410,
        enable: true,
        metaTitleSuffix: 'Aston Martin Dresden',
        projectName: 'Aston Martin Dresden',
        searchPath: '/verfuegbare-fahrzeuge',
        useOldRouter: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: false,
        linkDisclaimer: '/datenschutzerklaerung',
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            sidebar: ['sofort', 'modell', 'aufbau', 'typ', 'km', 'preis', 'rate', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]modell', '[SM]typ', '[LG]sofort', '[LG]motor', 'getriebe', 'aufbau', 'km', 'preis', 'rate', 'ez', 'aus'],
        },
        forcedFilter: {
            make: 7,
        },
        hideContactPersons: true,
    },
    style: {
        ...configDefault.style,
        borderRadius: 1,
        fontFamily: '"AM-Sans-Regular", sans-serif',
        fontSize: 16,
        scrollOffset: 100,
        muiComponents: () => ({
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                background: '<colorSecondary>',
                            },
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                background: '<colorSecondary>',
                            },
                        },
                    },
                    outlined: {
                        [getStyleQuery()]: {
                            'color': '<colorPrimary>',
                            'background': '#fff',
                            'borderColor': '<colorPrimary>',
                            '&.Mui-disabled, &.Mui-disabled:hover': {
                                borderColor: 'rgba(145, 158, 171, 0.24)',
                                color: 'rgba(145, 158, 171, 0.8)',
                            },
                        },
                    },
                    text: {
                        [getStyleQuery()]: {
                            'background': 'rgba(0, 102, 94, 0.16)',
                            'color': '<colorPrimary>',
                            '&:hover': {
                                background: 'rgba(0, 102, 94, 0.32)',
                                boxShadow: 'rgba(0, 102, 94, 0.24) 0px 8px 16px 0px',
                            },
                        },
                    },
                },
            },
            MuiFab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                background: '<colorSecondary>',
                            },
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    input: {
                        [getStyleQuery()]: {
                            background: '#fff',
                        },
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="AusstattungLocationInfo_Card"] a.MuiLink-root.MuiLink-underlineHover.MuiButton-text.MuiButton-textPrimary': {
                                'background': 'rgba(0, 102, 94, 0.16)',
                                'color': '<colorPrimary>',
                                '&:hover': {
                                    background: 'rgba(0, 102, 94, 0.32)',
                                    boxShadow: 'rgba(0, 102, 94, 0.24) 0px 8px 16px 0px',
                                },
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            button: {
                [getStyleQuery()]: {
                    fontFamily: 'AM-Flare_Bold, sans-serif',
                    fontSize: '15.2px',
                    fontWeight: 400,
                },
            },
            h3: {
                [getStyleQuery()]: {
                    color: '<colorPrimary>',
                    fontFamily: 'AM-Flare_Bold',
                    fontWeight: 700,
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontFamily: 'AM-Flare_Bold',
                    fontWeight: 700,
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontFamily: 'AM-Flare_Bold',
                    fontWeight: 700,
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontFamily: 'AM-Flare_Bold',
                    fontWeight: 700,
                },
            },
        }),
        primaryColor: '#00665E',
        secondaryColor: '#121212',
    },
};

export default configThomasExclusiveAstonMartin;
