/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Phillip Kühn
 */

import { getStyleQuery } from '../../utils/tools';
import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';

const configNextCamper: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 10200,
        enable: true,
        metaTitlePrefix: 'Next Camper',
        projectName: 'Next Camper',
        searchPath: '/wohnmobil-kaufen',
        useOldRouter: true,
    },
    cartile: {
        ...configDefault.cartile,
        badges: ['availableAt'],
    },
    contact: {
        ...configDefault.contact,
        buttons: {
            ...configDefault.contact.buttons,
            offsetY: 7,
        },
        isLeadSystem: true,
        linkDisclaimer: 'https://www.vw-dresden-reick.de/de/rechtliches/datenschutz.html',
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        forcedFilter: {
            ...configDefault.fahrzeugsuche.forcedFilter,
            // kategorie: 51, ToDo
        },
    },
    style: {
        ...configDefault.style,
        borderRadius: 1,
        fontFamily: '"Muli", "Open Sans", "Arial", sans-serif',
        fontSize: 14,
        scrollOffset: 100,
        muiComponents: () => ({
            MuiTab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            outline: 'none !important',
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            h3: {
                [getStyleQuery()]: {
                    fontFamily: '"Oswald", ' + '<fontFamily>',
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontFamily: '"Oswald", ' + '<fontFamily>',
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontFamily: '"Oswald", ' + '<fontFamily>',
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontFamily: '"Oswald", ' + '<fontFamily>',
                },
            },
        }),
        primaryColor: '#dc4405',
    },
};

export default configNextCamper;
