import { Stack, Tooltip, Typography } from '@mui/material';
import { TFunction } from 'i18next';
import { ReactNode } from 'react';
import { CarDataField, ConfigObject, Lang } from '../_configs/types';
import CarChassisIcon from '../_tools/svg/CarChassisIcon';
import { Effizienz } from '../components/effizienz/effizienz';
import { effizienzSteps } from '../components/effizienz/steps';
import { EffizienzClass } from '../components/effizienz/types';
import Iconify from '../components/iconify/component';
import { Image } from '../components/image';
import { formatDate } from '../utils/date';
import { checkUndefinedNullOrEmpty, checkUndefinedOrNull } from '../utils/tools';
import { DEV, LOCALSERVER, NEXT, PRE_RELEASE, TEST } from '../vars';
import sIntSiegel from './s_int_siegel';

function sStrDatafields(translate: TFunction, settings: ConfigObject, isCarDetailView?: boolean, showCarVarbrauchDetailsButton?: ReactNode): CarDataField[] {
    return [
        { i: 'antrieb', t: translate('common:car.antrieb'), icon: () => CarChassisIcon, lineBreak: true },
        {
            i: 'engine.hubraum', t: translate('common:car.engine.hubraum'), icon: () => 'mdi:piston', lineBreak: false, renderValue: function (car) {
                return !checkUndefinedNullOrEmpty(car?.engine?.hubraum) ? `${car?.engine?.hubraum?.toLocaleString('de-DE', { maximumFractionDigits: 0 })} cm³` : undefined;
            },
        },
        {
            i: 'engine.leistung', t: translate('common:car.engine.leistung'), icon: () => 'mdi:speedometer', lineBreak: true, renderValue: function (car) {
                return (
                    !checkUndefinedNullOrEmpty(car?.engine?.leistungKw)
                        ? `${car?.engine?.leistungKw?.replace(' ', '\u00A0')} (${car?.engine?.leistung?.replace(' ', '\u00A0')})`
                        : car?.engine?.leistung
                );
            },
        },
        {
            i: 'engine.motor', t: translate('common:car.engine.motor'), icon: () => 'mdi:engine', lineBreak: true, renderTextValue: function (car) {
                return !checkUndefinedNullOrEmpty(car?.engine?.motor) ? car?.engine?.motor : '-';
            }, renderValue: function (car) {
                return (
                    !checkUndefinedNullOrEmpty(car?.engine?.motor)
                        ? car?.engine?.motorTooltip
                            ? <Tooltip title={car?.engine?.motorTooltip}>
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                    {car?.engine?.motor}
                                    <Iconify icon="mdi:information" style={{ marginLeft: 2 }} />
                                </span>
                            </Tooltip>
                            : car?.engine?.motor
                        : '-'
                );
            },
        },
        {
            i: 'ez', t: translate('common:car.ez'), icon: () => 'mdi:calendar-today', lineBreak: false, renderValue: function (car) {
                return !checkUndefinedOrNull(car?.ez) ? formatDate(car?.ez, 'MM.yyyy') : undefined;
            },
        },
        { i: 'farbe', t: translate('common:car.farbe'), icon: () => 'mdi:palette', lineBreak: true, renderValue: function (car) { return settings?.general?.language !== Lang.deDE ? (!checkUndefinedNullOrEmpty(car?.farbe?.grund) ? car?.farbe?.grund : '-') : (!checkUndefinedNullOrEmpty(car?.farbe) ? (car?.farbe?.name || car?.farbe?.grund) : '-'); } },
        { i: 'garantie', t: translate('common:car.garantie'), icon: () => 'mdi:shield-car', lineBreak: true, renderValue: function (car) { return (!checkUndefinedNullOrEmpty(car?.garantie) ? (car?.garantie ? 'Ja' : 'Nein') : '-'); } },
        { i: 'getriebe', t: translate('common:car.getriebe'), icon: () => 'mdi:car-shift-pattern', lineBreak: false },
        { i: 'ident', t: translate('common:car.ident'), icon: () => 'mdi:identifier', lineBreak: false },
        {
            i: 'km', t: translate('common:car.km'), icon: () => 'mdi:counter', lineBreak: false, renderValue: function (car) {
                return !checkUndefinedNullOrEmpty(car?.km) ? `${car?.km?.toLocaleString('de-DE', { maximumFractionDigits: 0 })} km` : undefined;
            },
        },
        {
            i: 'siegel', t: translate('common:car.siegel'), icon: () => 'mdi:shield-car', lineBreak: true, renderValue: function (car) {
                const siegel = sIntSiegel?.find((s) => s?.i === car?.siegel);
                const imageBaseUrl = (LOCALSERVER ? '' : `https://hpt${DEV ? '-dev' : NEXT ? '-next' : PRE_RELEASE ? '-pre-release' : TEST ? '-test' : ''}.autohaus-digital.de`);

                return (
                    !checkUndefinedNullOrEmpty(siegel?.img)
                        ? <Image alt={`Siegel ${siegel?.t}`} disablePlaceholder externalImage src={`${imageBaseUrl}${siegel?.img}`} contain sx={{ height: '1.5em' }} objectPosition="center left" />
                        : (siegel?.t || '-')
                );
            },
        },
        { i: 'sitze', t: translate('common:car.sitze'), icon: () => 'mdi:car-seat', lineBreak: false },
        { i: 'standort', t: translate('common:car.standort'), icon: () => 'mdi:map-marker', lineBreak: true, renderValue: function (car) { return (!checkUndefinedNullOrEmpty(car?.loc) ? (car?.loc?.alias || car?.loc?.name || '-') : '-'); } },
        { i: 'tueren', t: translate('common:car.tueren'), icon: () => 'mdi:car-door', lineBreak: false },
        { i: 'typ', t: translate('common:car.typ'), icon: () => 'mdi:format-list-bulleted-type', lineBreak: true },

        // VERBRAUCH
        {
            i: 'verbrauch.combined', t: translate('common:carView.verbrauch.combined'), icon: () => 'mdi:chart-donut', lineBreak: false, renderTextValue: function (car) {
                return !checkUndefinedNullOrEmpty(car?.verbrauch?.combined) ? car?.verbrauch?.combined : '-';
            }, renderValue: function (car) {
                return `${car?.verbrauch?.combined || '-'} *`;
            },
        },
        {
            i: 'verbrauch.combinedWeighted', t: translate('common:carView.verbrauch.combinedWeighted'), icon: () => ({ main: 'mdi:chart-donut', badge: 'mdi:weight-kilogram' }), lineBreak: false, renderTextValue: function (car) {
                return !checkUndefinedNullOrEmpty(car?.verbrauch?.combined) ? car?.verbrauch?.combined : '-';
            }, renderValue: function (car) {
                return `${car?.verbrauch?.combined || '-'} *`;
            },
        },
        {
            i: 'verbrauch.combinedWeightedShort', t: translate('common:carView.verbrauch.combinedWeightedShort'), icon: () => ({ main: 'mdi:chart-donut', badge: 'mdi:weight-kilogram' }), lineBreak: false, renderTextValue: function (car) {
                return !checkUndefinedNullOrEmpty(car?.verbrauch?.combined) ? car?.verbrauch?.combined : '-';
            }, renderValue: function (car) {
                return `${car?.verbrauch?.combined || '-'} *`;
            },
        },
        {
            i: 'verbrauch.combinedDischarged', t: `${translate('common:carView.verbrauch.combined')} ${translate('common:carView.verbrauch.discharged')}`, icon: () => ({ main: 'mdi:chart-donut', badge: 'mdi:battery-off' }), lineBreak: false, renderTextValue: function (car) {
                return !checkUndefinedNullOrEmpty(car?.verbrauch?.combinedDischarged) ? car?.verbrauch?.combinedDischarged : '-';
            }, renderValue: function (car) {
                return `${car?.verbrauch?.combinedDischarged || '-'} *`;
            },
        },
        {
            i: 'verbrauch.co2costsLow', t: translate('common:carView.verbrauch.co2costsLow'), icon: () => ({ main: 'mdi:molecule-co2', badge: 'mdi:currency-eur' }), lineBreak: false, renderTextValue: function (car) {
                return !checkUndefinedNullOrEmpty(car?.verbrauch?.co2costsLow) ? car?.verbrauch?.co2costsLow : '-';
            }, renderValue: function (car) {
                return `${car?.verbrauch?.co2costsLow || '-'} *`;
            },
        },
        {
            i: 'verbrauch.co2costsMedium', t: translate('common:carView.verbrauch.co2costsMedium'), icon: () => ({ main: 'mdi:molecule-co2', badge: 'mdi:currency-eur' }), lineBreak: false, renderTextValue: function (car) {
                return !checkUndefinedNullOrEmpty(car?.verbrauch?.co2costsMedium) ? car?.verbrauch?.co2costsMedium : '-';
            }, renderValue: function (car) {
                return `${car?.verbrauch?.co2costsMedium || '-'} *`;
            },
        },
        {
            i: 'verbrauch.co2costsHigh', t: translate('common:carView.verbrauch.co2costsHigh'), icon: () => ({ main: 'mdi:molecule-co2', badge: 'mdi:currency-eur' }), lineBreak: false, renderTextValue: function (car) {
                return !checkUndefinedNullOrEmpty(car?.verbrauch?.co2costsHigh) ? car?.verbrauch?.co2costsHigh : '-';
            }, renderValue: function (car) {
                return `${car?.verbrauch?.co2costsHigh || '-'} *`;
            },
        },
        {
            i: 'verbrauch.co2class', t: translate('common:carView.verbrauch.co2class'), icon: () => 'icon-park-solid:leaves-two', lineBreak: false, renderTextValue: function (car) {
                return effizienzSteps?.find((e) => e?.i === car?.verbrauch?.effizienz)?.t;
            }, renderValue: function (car) {
                return (<Stack direction="row" gap={0.5} alignItems="center" justifyContent="space-between" sx={{ width: 1 }}>
                    <Effizienz type="step" step={car?.verbrauch?.effizienz || EffizienzClass.N_A} stepHeight={settings?.style?.fontSize} />
                    <Typography variant="caption">*</Typography>
                </Stack>);
            },
        },
        {
            i: 'verbrauch.effizienzWeighted', t: translate('common:carView.verbrauch.co2classWeighted'), icon: () => ({ main: 'icon-park-solid:leaves-two', badge: 'mdi:weight-kilogram' }), lineBreak: false, renderTextValue: function (car) {
                return effizienzSteps?.find((e) => e?.i === car?.verbrauch?.effizienz)?.t;
            }, renderValue: function (car) {
                return (<Stack direction="row" gap={0.5} alignItems="center" justifyContent="space-between" sx={{ width: 1 }}>
                    <Effizienz type="step" step={car?.verbrauch?.effizienz || EffizienzClass.N_A} stepHeight={settings?.style?.fontSize} />
                    <Typography variant="caption">*</Typography>
                </Stack>);
            },
        },
        {
            i: 'verbrauch.effizienzBig', t: translate('common:carView.verbrauch.co2class'), icon: () => 'icon-park-solid:leaves-two', headingExtraLine: true, lineBreak: false, noTypo: true, renderTextValue: function (car) {
                return effizienzSteps?.find((e) => e?.i === car?.verbrauch?.effizienz)?.t;
            }, renderValue: function (car) {
                return (<Stack direction="row" gap={0.5} alignItems="center" justifyContent="space-between" sx={{ width: 1 }}>
                    <Effizienz type="stair" steps={{ combined: car?.verbrauch?.effizienz || EffizienzClass.N_A, discharged: car?.verbrauch?.effizienzDischarged }} stepHeight={settings?.style?.fontSize + 2} />
                    <Typography variant="caption">*</Typography>
                </Stack>);
            },
        },
        {
            i: 'verbrauch.effizienzDischarged', t: `${translate('common:carView.verbrauch.co2class')} ${translate('common:carView.verbrauch.discharged')}`, icon: () => ({ main: 'icon-park-solid:leaves-two', badge: 'mdi:battery-off' }), lineBreak: false, renderTextValue: function (car) {
                return effizienzSteps?.find((e) => e?.i === car?.verbrauch?.effizienzDischarged)?.t;
            }, renderValue: function (car) {
                return (<Stack direction="row" gap={0.5} alignItems="center" justifyContent="space-between" sx={{ width: 1 }}>
                    <Effizienz type="step" step={car?.verbrauch?.effizienzDischarged || EffizienzClass.N_A} stepHeight={settings?.style?.fontSize} />
                    <Typography variant="caption">*</Typography>
                </Stack>);
            },
        },
        {
            i: 'verbrauch.emission', t: translate('common:carView.verbrauch.emission'), icon: () => 'mdi:molecule-co2', lineBreak: false, renderTextValue: function (car) {
                return !checkUndefinedNullOrEmpty(car?.verbrauch?.emission) ? car?.verbrauch?.emission : '-';
            }, renderValue: function (car) {
                return `${car?.verbrauch?.emission || '-'} *`;
            },
        },
        {
            i: 'verbrauch.emissionWeighted', t: translate('common:carView.verbrauch.emissionWeighted'), icon: () => ({ main: 'mdi:molecule-co2', badge: 'mdi:weight-kilogram' }), lineBreak: false, renderTextValue: function (car) {
                return !checkUndefinedNullOrEmpty(car?.verbrauch?.emission) ? car?.verbrauch?.emission : '-';
            }, renderValue: function (car) {
                return `${car?.verbrauch?.emission || '-'} *`;
            },
        },
        {
            i: 'verbrauch.emissionWeightedShort', t: translate('common:carView.verbrauch.emissionWeightedShort'), icon: () => ({ main: 'mdi:molecule-co2', badge: 'mdi:weight-kilogram' }), lineBreak: false, renderTextValue: function (car) {
                return !checkUndefinedNullOrEmpty(car?.verbrauch?.emission) ? car?.verbrauch?.emission : '-';
            }, renderValue: function (car) {
                return `${car?.verbrauch?.emission || '-'} *`;
            },
        },
        {
            i: 'verbrauch.emissionDischarged', t: `${translate('common:carView.verbrauch.combined')} ${translate('common:carView.verbrauch.discharged')}`, icon: () => ({ main: 'mdi:molecule-co2', badge: 'mdi:battery-off' }), lineBreak: false, renderTextValue: function (car) {
                return !checkUndefinedNullOrEmpty(car?.verbrauch?.emissionDischarged) ? car?.verbrauch?.emissionDischarged : '-';
            }, renderValue: function (car) {
                return `${car?.verbrauch?.emissionDischarged || '-'} *`;
            },
        },
        {
            i: 'verbrauch.energycosts', t: translate('common:carView.verbrauch.energycosts'), icon: () => ({ main: 'mdi:flash', badge: 'mdi:currency-eur' }), lineBreak: false, renderTextValue: function (car) {
                return !checkUndefinedNullOrEmpty(car?.verbrauch?.energycosts) ? car?.verbrauch?.energycosts : '-';
            }, renderValue: function (car) {
                return `${car?.verbrauch?.energycosts || '-'} *`;
            },
        },
        {
            i: 'verbrauch.euronorm', t: translate('common:carView.verbrauch.euronorm'), icon: () => 'mdi:file-certificate', lineBreak: false, renderTextValue: function (car) {
                return !checkUndefinedNullOrEmpty(car?.verbrauch?.euronorm) ? car?.verbrauch?.euronorm : '-';
            }, renderValue: function (car) {
                return `${car?.verbrauch?.euronorm || '-'} *`;
            },
        },
        {
            i: 'verbrauch.wltp.low', t: translate('common:carView.verbrauch.low'), icon: () => 'mdi:city', lineBreak: false, renderTextValue: function (car) {
                return car?.verbrauch?.low || '-';
            }, renderValue: function (car) {
                return `${car?.verbrauch?.low || '-'} *`;
            },
        },
        {
            i: 'verbrauch.wltp.lowDischarged', t: translate('common:carView.verbrauch.low'), icon: () => ({ main: 'mdi:city', badge: 'mdi:battery-off' }), lineBreak: false, renderTextValue: function (car) {
                return car?.verbrauch?.lowDischarged || '-';
            }, renderValue: function (car) {
                return `${car?.verbrauch?.lowDischarged || '-'} *`;
            },
        },
        {
            i: 'verbrauch.wltp.medium', t: translate('common:carView.verbrauch.medium'), icon: () => 'fa6-solid:tree-city', lineBreak: false, renderTextValue: function (car) {
                return car?.verbrauch?.medium || '-';
            }, renderValue: function (car) {
                return `${car?.verbrauch?.medium || '-'} *`;
            },
        },
        {
            i: 'verbrauch.wltp.mediumDischarged', t: translate('common:carView.verbrauch.medium'), icon: () => ({ main: 'fa6-solid:tree-city', badge: 'mdi:battery-off' }), lineBreak: false, renderTextValue: function (car) {
                return car?.verbrauch?.mediumDischarged || '-';
            }, renderValue: function (car) {
                return `${car?.verbrauch?.mediumDischarged || '-'} *`;
            },
        },
        {
            i: 'verbrauch.wltp.high', t: translate('common:carView.verbrauch.high'), icon: () => 'streamline:street-road', lineBreak: false, renderTextValue: function (car) {
                return car?.verbrauch?.high || '-';
            }, renderValue: function (car) {
                return `${car?.verbrauch?.high || '-'} *`;
            },
        },
        {
            i: 'verbrauch.wltp.highDischarged', t: translate('common:carView.verbrauch.high'), icon: () => ({ main: 'streamline:street-road', badge: 'mdi:battery-off' }), lineBreak: false, renderTextValue: function (car) {
                return car?.verbrauch?.highDischarged || '-';
            }, renderValue: function (car) {
                return `${car?.verbrauch?.highDischarged || '-'} *`;
            },
        },
        {
            i: 'verbrauch.wltp.extraHigh', t: translate('common:carView.verbrauch.extraHigh'), icon: () => 'mdi:autobahn', lineBreak: false, renderTextValue: function (car) {
                return car?.verbrauch?.extraHigh || '-';
            }, renderValue: function (car) {
                return `${car?.verbrauch?.extraHigh || '-'} *`;
            },
        },
        {
            i: 'verbrauch.wltp.extraHighDischarged', t: translate('common:carView.verbrauch.extraHigh'), icon: () => ({ main: 'mdi:autobahn', badge: 'mdi:battery-off' }), lineBreak: false, renderTextValue: function (car) {
                return car?.verbrauch?.extraHighDischarged || '-';
            }, renderValue: function (car) {
                return `${car?.verbrauch?.extraHighDischarged || '-'} *`;
            },
        },
        {
            i: 'verbrauch.wltp.range', t: translate('common:carView.verbrauch.range'), icon: () => 'mdi:map-marker-distance', lineBreak: false, renderTextValue: function (car) {
                return car?.verbrauch?.range || '-';
            }, renderValue: function (car) {
                return `${car?.verbrauch?.range || '-'} *`;
            },
        },
        {
            i: 'verbrauch.title', t: translate('common:carView.verbrauch.title'), fullWidth: true, icon: (car) => (car?.engine?.motor === 'Elektro' ? 'solar:electric-refueling-bold-duotone' : 'solar:fuel-bold-duotone'), lineBreak: false, noTooltip: true, noTypo: true, renderValue: function () {
                return <Stack direction="row" gap={1}>
                    {translate('common:carView.verbrauch.title')}
                    {showCarVarbrauchDetailsButton}
                </Stack>;
            },
        },
    ];
}
export default sStrDatafields;
