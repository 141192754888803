import { TFunction } from 'i18next';
import { Facets, Filter } from '../_types/Car';

type MapFacet = {
    facetName: (keyof Facets) | 'searchText',
    filterName: string,
    einheit?: string,
};

function mapFacets(translate: TFunction): Record<(keyof Filter) | 'fiz', MapFacet> {
    return {
        'accessoirs': { facetName: 'accessoirs', filterName: translate('common:filter.accessoirs') },
        'searchText': { facetName: 'searchText', filterName: translate('common:filter.searchText') },
        'make': { facetName: 'make', filterName: translate('common:filter.make') },
        'model': { facetName: 'model', filterName: translate('common:filter.model') },
        'condition': { facetName: 'condition', filterName: translate('common:filter.condition') },
        'fuel': { facetName: 'fuel', filterName: translate('common:filter.fuel') },
        // 'motoren': { facetName: 'MotorGAll', filterName: translate('common:filter.motor') },
        'gearbox': { facetName: 'gearbox', filterName: translate('common:filter.gearbox') },
        'location': { facetName: 'location', filterName: translate('common:filter.location') },
        'category': { facetName: 'category', filterName: translate('common:filter.category') },
        'vehicleClass': { facetName: 'vehicleClass', filterName: translate('common:filter.vehicleClass') },
        'siegel': { facetName: null, filterName: translate('common:filter.siegel') }, // ToDo facetName
        'exteriorColor': { facetName: 'exteriorColor', filterName: translate('common:filter.exteriorColor') },
        'price': { facetName: 'price', filterName: translate('common:filter.price'), einheit: '€' },
        // 'Rate': { facetName: 'rateAll', filterName: translate('common:filter.rate'), einheit: '€' },
        // 'RateVon': { facetName: 'rateAll', filterName: null },
        // 'RateBis': { facetName: 'rateAll', filterName: null },
        'firstRegistration': { facetName: 'firstRegistration', filterName: translate('common:filter.firstRegistration') },
        // 'EZVon': { facetName: 'ezAll', filterName: null },
        // 'EZBis': { facetName: 'ezAll', filterName: null },
        'mileage': { facetName: 'mileage', filterName: translate('common:filter.mileage'), einheit: 'km' },
        // 'KMVon': { facetName: 'kmAll', filterName: null },
        // 'KMBis': { facetName: 'kmAll', filterName: null },
        'power': { facetName: 'power', filterName: translate('common:filter.power'), einheit: 'kW' },
        // 'LeistungVon': { facetName: 'LeistungAll', filterName: null },
        // 'LeistungBis': { facetName: 'LeistungAll', filterName: null },
        'available': { facetName: null, filterName: translate('common:filter.available') },
        'special': { facetName: null, filterName: translate('common:filter.special') },
        'rate': { facetName: 'rate', filterName: translate('common:filter.rate') },
        'fiz': { facetName: null, filterName: null },
        'sort': { facetName: null, filterName: null },
        'order': { facetName: null, filterName: null },
        'page': { facetName: null, filterName: null },
        'pageSize': { facetName: null, filterName: null },
        'ignoreFirma': { facetName: null, filterName: null },
        'ignoreStandort': { facetName: null, filterName: null },
        'nfzHersteller': { facetName: null, filterName: null },
        'NFZ': { facetName: null, filterName: null },
        'tags': { facetName: 'tags', filterName: translate('common:filter.tags') },
        'fd': { facetName: null, filterName: translate('common:filter.fd') }, // ToDo facetName
    };
}

export default mapFacets;
