import { Lang } from '../_configs/types';
import { FaarenCarPricePlan, FaarenPickupDeliveryOption } from '../_tools/types/faaren';
import { EffizienzClass } from '../components/effizienz/types';
import { IconifyProps } from '../components/iconify/types';
import { BikeDrivingMode, CarCategory, CarDoors, CarGearbox, EmissionStickers, TruckDrivingCab, WheelFormula } from './cars/enums';
import { AdWithAdd } from './mobile/AdWithAdd';
import { Dimension } from './mobile/Dimension';

export type SelectOption = {
    i: string | number,
    iSend?: string | string[] | number | number[]
    t: string,
    et?: string,
    icon?: IconifyProps,
    group?: string,
    details?: string[],
    nested?: number,
    showLabel?: boolean,
};

export type CarPlugType = 'TYPE_1' | 'TYPE_2' | 'CCS_COMBO' | 'CHADEMO' | 'SCHUKO' | 'TESLA_SC';

export enum CarKategorie {
    MOTORRAD = 'KRAD',
    PKW = 'PKW',
}

export type User = {
    i?: number,
    vn?: string,
    nn?: string,
    m?: string,
    t?: string,
    img?: string
};

export type UserSelect = {
    i?: number,
    idf?: number,
    a?: string,
    s?: number,
    vn?: string,
    nn?: string,
    m?: string,
    t?: string,
    img?: string
};

export type CarVerbrauchParsed = {
    combined: string,
    combinedSecondary: string,
    combinedDischarged: string,
    effizienz: EffizienzClass,
    effizienzDischarged: EffizienzClass,
    emission: string,
    emissionDischarged: string,
    energycosts: string,
    envkv: boolean,
    co2costsLow: string,
    co2costsMedium: string,
    co2costsHigh: string,
    euronorm: string,
    extraHigh: string,
    extraHighDischarged: string,
    high: string,
    highDischarged: string,
    motor: string,
    low: string,
    lowDischarged: string,
    medium: string,
    mediumDischarged: string,
    range: string,
    rangeExtender: boolean,
};

export type CarVerbrauchParsedNumbered = {
    combined: number,
    combinedSecondary: number,
    combinedDischarged: number,
    effizienz: EffizienzClass,
    effizienzDischarged: EffizienzClass,
    emission: number,
    emissionDischarged: number,
    energycosts: number,
    co2costsLow: number,
    co2costsMedium: number,
    co2costsHigh: number,
    extraHigh: number,
    extraHighDischarged: number,
    high: number,
    highDischarged: number,
    low: number,
    lowDischarged: number,
    medium: number,
    mediumDischarged: number,
    range: number,
};

export type CarAusstattungKey = keyof Omit<Car<false>['desc'], 'fuss' | 'isList' | 'kopf'>;
export const sortedAusCat: CarAusstattungKey[] = [
    // TODO
//     'highlights',
//     'pakete',
//     'assistenz',
//     'multimedia',
//     'innen',
//     'sitze',
//     'scheinwerfer',
//     'aussen',
//     'raeder',
//     'technik',
//     'sonstiges',
//     'fussnoten',
];

export type Car<LiveFields extends boolean> = {
    aktion?: boolean,
    antrieb?: string,
    aufbau?: CarCategory,
    ausstattung?: Record<LiveFields extends true ? string : CarAusstattungKey, {
        txt?: string,
        typ?: number,
        sg?: number,
        style?: number,
        w?: number,
        origin?: string,
        kat?: number
    }[]>,
    autoAbo?: {
        additional_milage_costs: number,
        damage_participation: number,
        delivery_option: FaarenPickupDeliveryOption[],
        delivery_time_after_booking: number,
        fixed_duration: boolean,
        immediately_available: boolean,
        min_license_duration: number,
        minimum_age: number,
        pick_up_option: FaarenPickupDeliveryOption[],
        plans: FaarenCarPricePlan[],
    },
    bilder?: {
        '360'?: string,
        '360a'?: string,
        '360a_w'?: number,
        '360a_h'?: number,
        anz360a?: number,
        bilder?: number,
        ec?: boolean,
        hd?: boolean,
        pano?: string,
        url?: string[],
        urlHD?: string[],
        urlS?: string[]
    },
    desc?: {
        left?: string,
        right?: string,
    },
    design?: string,
    engine?: {
        effizienz?: string,
        envkv?: boolean,
        hubraum?: number,
        kraftstoff?: string,
        kraftstoff_1?: string,
        leistung?: string,
        motor?: string,
        motorTooltip?: string,
        plakette?: EmissionStickers,
        schadstoff?: string,
        leistungKw: string,
        leistungKwNumbered: number,
    },
    ez?: LiveFields extends true ? Date : string,
    fahrbereit?: boolean,
    farbe?: {
        grund?: string,
        name?: string,
    },
    farbe_innen?: {
        grund?: string,
        name?: string,
    },
    finanzierung?: {
        abschlussgebühr?: number,
        anbieterbank?: string,
        anzahlung?: number,
        art_sollzinssatzes?: string,
        bruttokreditbetrag?: number,
        effektiver_jahreszins?: number,
        km?: number,
        ksb?: string,
        ksb_betrag?: number,
        laufzeit?: number,
        monatliche_rate?: number,
        nettrokreditbetrag?: number,
        schlussrate?: number,
        soll_zinssatz?: number,
        text1?: string,
        text2?: string,
    },
    garantie?: boolean,
    getriebe?: CarGearbox,
    herkunft?: number,
    hersteller?: string,
    hsn?: string,
    hu?: LiveFields extends true ? Date : string,
    id?: number,
    idStandort?: number,
    ident?: string,
    kategorie?: CarKategorie,
    km?: number,
    idFirma?: number,
    is_eyecatcher?: string,
    is_id_logo?: number,
    is_id_firmenimage?: number,
    land?: string,
    leasing?: {
        anbieterbank?: string,
        anzahlung?: number,
        DestinationCharges?: number,
        effektiver_jahreszins?: number,
        ExtraMileageCosts?: number,
        km?: number,
        laufzeit?: number,
        LowMileageCompensation?: number,
        monatliche_rate?: number,
        monatliche_rate_netto?: number,
        nettrokreditbetrag?: number,
        RegistrationFees?: number,
        soll_zinssatz?: number,
        TotalAmount?: number,
        vk?: number,
        zielgruppe?: 'PRIVATE',
    },
    leasingComm?: {
        anbieterbank?: string,
        anzahlung?: number,
        DestinationCharges?: number,
        ExtraMileageCosts?: number,
        effektiver_jahreszins?: number,
        km?: number,
        laufzeit?: number,
        LowMileageCompensation?: number,
        monatliche_rate?: number,
        monatliche_rate_netto?: number,
        nettrokreditbetrag?: number,
        RegistrationFees?: number,
        soll_zinssatz?: number,
        TotalAmount?: number,
        vk?: number,
        zielgruppe?: 'COMMERCIAL',
    },
    lHighlights?: string[],
    lieferzeit?: number,
    loc?: {
        alias?: string,
        hm?: string,
        id?: number,
        image?: string,
        /** @deprecated Use latitude instead. */
        lat?: number,
        latitude?: number,
        /** @deprecated Use longitude instead. */
        lon?: number,
        longitude?: number,
        mail?: string,
        name?: string,
        openMo?: string,
        openDi?: string,
        openMi?: string,
        openDo?: string,
        openFr?: string,
        openSa?: string,
        openSo?: string,
        openServiceMo?: string,
        openServiceDi?: string,
        openServiceMi?: string,
        openServiceDo?: string,
        openServiceFr?: string,
        openServiceSa?: string,
        openServiceSo?: string,
        openWerkstattMo?: string,
        openWerkstattDi?: string,
        openWerkstattMi?: string,
        openWerkstattDo?: string,
        openWerkstattFr?: string,
        openWerkstattSa?: string,
        openWerkstattSo?: string,
        ort?: string,
        plz?: string,
        str?: string,
        tel?: string,
        tel_hpt?: string,
        url?: string,
    },
    mod_g?: string,
    mod_o?: string,
    modelkey?: string,
    modelkey6?: string,
    modell?: string,
    modelljahr?: LiveFields extends true ? Date : string,
    name?: string,
    nameSlug?: string,
    nfz?: boolean,
    polster?: string,
    preis?: {
        mwst?: number,
        mwst_satz?: number,
        transport?: number,
        upe?: number,
        vk?: number,
        vkn?: number,
    },
    scheckheft?: boolean,
    siegel?: number,
    simc?: Record<Car<LiveFields>['id'], Car<LiveFields>>,
    sitze?: number,
    sonderangebot?: boolean,
    standort?: string,
    typ?: string,
    tueren?: CarDoors,
    tsn?: string,
    unfall?: boolean,
    user?: User['i'][],
    users?: User[],
    verfuegbarAb?: LiveFields extends true ? Date : string,
    vin?: string,
    vorbesitzer?: number,
    usersSelect?: UserSelect[],
    verbrauch?: CarVerbrauchParsed,
    verbrauchNumbered?: CarVerbrauchParsedNumbered,
    axles?: number,
    wheelFormula?: WheelFormula
    chargingTime?: number,
    chargingTimeFast?: number,
    plugTypes?: string[],
    e10Enabled?: boolean,
    catalyticConverter?: boolean,
    particulateFilterDiesel?: boolean,
    biodieselConversion?: boolean,
    biodieselSuitable?: boolean,
    vegetableoilfuelConversion?: boolean,
    vegetableoilfuelSuitable?: boolean,
    metallic?: boolean,
    constructionYear?: number,
    constructionDate?: LiveFields extends true ? Date : string,
    nonSmokerVehicle?: boolean,
    damageUnrepaired?: boolean,
    accidentDamaged?: boolean,
    drivingCab?: TruckDrivingCab,
    drivingMode?: BikeDrivingMode,
    europalletStorageSpaces?: number,
    installationHeight?: number,
    licensedWeight?: number,
    liftingCapacity?: number,
    liftingHeight?: number,
    loadCapacity?: number,
    loadingSpace?: Dimension,
    municipal?: boolean,
    operatingHours?: number,
    battery?: string,
    trimLine?: string,
    modelRange?: string,
    firstModelsProductionDate?: LiveFields extends true ? Date : string,
    batteryCapacity?: number,
    shippingVolume?: number,
    videoUrl?: string,
};

export type NavigateCar = {
    hersteller?: Car<false>['hersteller'],
    id?: Car<false>['id'],
    loc?: Car<false>['loc'],
    modell?: Car<false>['modell'],
    name?: Car<false>['name'],
    nameSlug?: Car<false>['nameSlug'],
    typ?: Car<false>['typ'],
};

export type CarField<
    T,
    P1 extends keyof NonNullable<T> = keyof NonNullable<T>,
> = [P1?, any?, any?];

export type CarList<LiveFields extends boolean> = {
    allCount: number;
    count: number;
    facets: Facets;
    cars: Record<Car<LiveFields>['id'], Car<LiveFields>>;
};

type HPTConverterSettings = {
    disableFinancing: boolean,
    disableLeasing: boolean,
    faarenApiKey: string,
    language: Lang,
    upeInsteadOfVk: boolean,
    useEyecatcher: boolean,
    useOldRouter: boolean,
};

export type FilterService = Partial<AdWithAdd> & {
    id?: number,
    idGroup: number,
    onlyFacetts?: 'true' | 'false',
    lang?: Lang,
    page?: string,
    pageSize?: string,
    sort?: 'make' | 'rate' | 'VerfuegbarAb' | 'vk' | 'stime',
    order?: 'ASC' | 'DESC',
    firstRegistrationFrom?: string,
    firstRegistrationTo?: string,
    mileageFrom?: string,
    mileageTo?: string,
    powerFrom?: string,
    powerTo?: string,
    priceFrom?: string,
    priceTo?: string,
    rate?: string,
    rateFrom?: string,
    rateTo?: string,
    location?: string,
    accessoirs?: string,
    special?: 'true' | 'false',
    available?: 'true' | 'false',
    fd?: 'true' | 'false',
    tags?: string,
} & (({ raw: 'true' } & HPTConverterSettings) | { raw: 'false' });

export enum FilterSort {
    Hersteller = 'Hersteller',
    Rate = 'rate',
    Standzeit = 'stime',
    VerfugbarAb = 'VerfuegbarAb',
    Verkaufspreis = 'vk',
    Zufall = 'random',
}

export enum FilterOrder {
    ASC = 'ASC',
    DESC = 'DESC',
}

export type Filter = {
    available?: boolean,
    condition?: string[],
    category?: string,
    firstRegistration?: number[],
    // firstRegistrationFrom?: number,
    // firstRegistrationTo?: number,
    exteriorColor?: string[],
    searchText?: string,
    fd?: number[],
    gearbox?: string,
    make?: string,
    accessoirs?: string[],
    // idFirma?: number[],
    ignoreFirma?: number[],
    ignoreStandort?: number[],
    mileage?: number[],
    // mileageFrom?: number,
    // mileageTo?: number,
    power?: number[],
    // powerFrom?: number,
    // powerTo?: number,
    fuel?: string,
    // fuels?: string[],
    location?: number[],
    model?: string,
    nfzHersteller?: number,
    NFZ?: boolean,
    order?: FilterOrder,
    page?: number,
    pageSize?: number,
    price?: number[],
    // priceFrom?: number,
    // priceTo?: number,
    rate?: number[],
    // rateFrom?: number,
    // rateTo?: number,
    siegel?: number,
    sort?: FilterSort,
    special?: boolean,
    tags?: string,
    vehicleClass?: number[],
};

export enum ViewOptionsView {
    Grid = 'grid',
    List = 'list',
}

export type ViewOptions = {
    advanced?: boolean,
    car?: [string, number] | undefined,
    view?: ViewOptionsView,
};

export type Facet = {
    /**
     * @name ID
     * @description Facet ID
     * @example VW
     */
    i: string,
    /**
     * @name Text
     * @description Facet label
     * @example Volkswagen
     */
    t: string,
    /**
     * @name Count
     * @description Number of found cars for this facet
     * @example 1
     */
    c: number,
}

export type LocFacet = Facet & {
    /**
     * @name Latitude
     * @description Describes the Lat value of the location
     * @example 123456789
     */
    lat?: number,
    /**
     * @name Longitude
     * @description Describes the Lon value of the location
     * @example 123456789
     */
    lon?: number,
    /**
     * @name Result Count
     * @description Describes the count of ToDo
     * @example: 1
     */
    rc?: number,
    /**
     * @name City
     * @description Describes the city of the location
     * @example: 'Dresden'
     */
    city?: string,
    /**
     * @name Postal Code
     * @description Describes the postal code of the location
     * @example: 01219
     */
    plz?: number,
    /**
     * @name Street
     * @description Describes the street of the location
     * @example: 'Großenhainer Str.'
     */
    str?: string,

}

export type Facets = {
    /**
     * @name accessoirs
     * @description Facet Accessoirs
     * @example [{ i: '', t: '', c: 1 }]
     */
    accessoirs: Facet[],
    /**
    * @name Category
    * @description Facet make
    * @example [{ i: 'VW', t: 'Volkswagen', c: 1 }]
    */
    category: Facet[],
    /**
     * @name Condition
     * @description Facet condition
     * @example [{ i: 'USED', t: 'Gebraucht', c: 1 }]
     */
    condition: Facet[],
    /**
     * @name Exterior Color
     * @description Facet exterior color
     * @example [{ i: 'BLACK', t: 'Schwarz', c: 1 }]
     */
    exteriorColor: Facet[],
    /**
     * @name First Registration
     * @description Facet first registration
     * @example [{ i: '2024', t: '2024', c: 1 }]
     */
    firstRegistration: Facet[],
    /**
     * @name Fuel
     * @description Facet fuel
     * @example [{ i: 'ELECTRICITY', t: 'Elektrisch', c: 1 }]
     */
    fuel: Facet[],
    /**
     * @name Gearbox
     * @description Facet gearbox
     * @example [{ i: 'MANUAL_GEAR', t: 'Manuell', c: 1 }]
     */
    gearbox: Facet[],
    /**
     * @name Location
     * @description Facet location
     * @example [{ i: ''Audi Zentrum Würzburg, t: 'Spindler Audi Zentrum Würzburg', c: 1 }]
     */
    location: LocFacet[],
    /**
    * @name Make
    * @description Facet make
    * @example [{ i: 'VW', t: 'Volkswagen', c: 1 }]
    */
    make: Facet[],
    /**
     * @name Milage
     * @description Facet milage
     * @example [{ i: '-1', t: '', c: 1 }]
     */
    mileage: Facet[],
    /**
    * @name Model
    * @description Facet model
    * @example [{ i: 'Passat Variant', t: 'Passat Variant', c: 1 }]
    */
    model: Facet[],
    /**
     * @name Power
     * @description Facet power
     * @example [{ i: '-1', t: '', c: 1 }]
     */
    power: Facet[],
    /**
     * @name Price
     * @description Facet price
     * @example [{ i: '-1', t: '', c: 1 }]
     */
    price: Facet[],
    /**
     * @name Rate
     * @description Facet rate
     * @example [{ i: '-1', t: '', c: 1 }]
     */
    rate: Facet[],
    /**
     * @name Tags
     * @description Facet tags
     * @example [{ i: '', t: '', c: 1 }]
     */
    tags: Facet[],
    /**
     * @name Vehicle Class
     * @description Facet vehicle class
     * @example [{ i: 'VanUpTo7500', t: 'Transporter', c: 1 }]
     */
    vehicleClass: Facet[],
};
