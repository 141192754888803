import { Filter, ViewOptions, ViewOptionsView } from '../_types/Car';

export const standardFilter: Filter = {
    sort: null,
    order: null,
    fd: [],
    // idFirma: [],
    location: [],
    make: null,
    model: null,
    category: null,
    siegel: -100,
    condition: [],
    price: null,
    firstRegistration: null,
    power: null,
    mileage: null,
    rate: null,
    fuel: null,
    // motoren: [],
    gearbox: null,
    available: null,
    accessoirs: [],
    tags: '-100',
    page: 1,
    pageSize: 18,
    searchText: '',
    exteriorColor: [],
    special: null,
};

export const standardViewOptions: ViewOptions = {
    advanced: false,
    car: undefined,
    view: ViewOptionsView.Grid,
};
