const sIntSiegel = [
    { i: 0, t: 'Kein Siegel' },
    { i: 1, t: 'GW:plus', img: '/media/siegel/1.png', imgRatio: [3, 1] },
    { i: 2, t: 'Zertifizierte Gebrauchtwagen', img: '/media/siegel/2.png', imgRatio: [94, 25] },
    { i: 8, t: 'BMW Premium Selection', mobileKey: 'BMW', img: '/media/siegel/8.png', imgRatio: [13, 3] },
    { i: 4, t: 'ZDK', img: '/media/siegel/4.png', imgRatio: [25, 24] },
    { i: 16, t: 'Tradeport', img: '/media/siegel/16.png', imgRatio: [139, 45] },
    { i: 32, t: 'Lexus Select', img: '/media/siegel/32.png', imgRatio: [96, 43] },
    { i: 64, t: 'Mercedes Benz Junge Sterne', mobileKey: 'MERCEDES_TRANSPORTER', img: '/media/siegel/64.png', imgRatio: [4, 1] },
    { i: 97, t: 'SPOTICAR', mobileKey: 'SPOTICAR', img: '/media/siegel/97.png', imgRatio: [650, 159] },
    { i: 98, t: 'CUPRA Approved', img: '/media/siegel/98.png', imgRatio: [1010, 233] },
    { i: 99, t: 'DS Certified Seal', mobileKey: 'DS_CERTIFIED', img: '/media/siegel/99.png', imgRatio: [96, 29] },
    { i: 100, t: 'CITROEN select', img: '/media/siegel/100.png', imgRatio: [44, 15] },
    { i: 101, t: 'MINI NEXT', mobileKey: 'MINI', img: '/media/siegel/101.png', imgRatio: [1, 1] },
    { i: 102, t: 'Renault Gebrauchtwagen - 7 Sterne Qualitat', img: '/media/siegel/102.png', imgRatio: [58, 15] },
    { i: 103, t: 'Ferrari Approved', mobileKey: 'FERRARI', img: '/media/siegel/103.png', imgRatio: [13, 3] },
    { i: 104, t: 'SEAT [Selection]', mobileKey: 'SEAT', img: '/media/siegel/104.png', imgRatio: [13, 3] },
    { i: 105, t: 'Skoda plus', mobileKey: 'SKODA', img: '/media/siegel/105.png', imgRatio: [123, 20] },
    { i: 106, t: 'Toyota geprüfte Gebrauchtwagen', img: '/media/siegel/106.png', imgRatio: [99, 41] },
    { i: 107, t: 'Transporter-Gebrauchtwagen-Center', img: '/media/siegel/107.png', imgRatio: [48, 11] },
    { i: 108, t: 'Honda qualityplus', img: '/media/siegel/108.png', imgRatio: [135, 52] },
    { i: 109, t: 'VOLVO SELEKT – Geprüfte Gebrauchtwagen mit Garantie', mobileKey: 'VOLVO', img: '/media/siegel/109.png', imgRatio: [1240, 257] },
    { i: 110, t: 'Opel Zertifizierte Gebrauchtwagen', img: '/media/siegel/110.png', imgRatio: [65, 23] },
    { i: 111, t: 'Bentley Pre-Owned', mobileKey: 'BENTLEY', img: '/media/siegel/111.png', imgRatio: [100, 37] },
    { i: 112, t: 'jung@smart', img: '/media/siegel/112.png', imgRatio: [60, 19] },
    { i: 113, t: 'Porsche Approved', mobileKey: 'PORSCHE', img: '/media/siegel/113.png', imgRatio: [8, 3] },
    { i: 114, t: 'Jaguar Approved', mobileKey: 'JAGUAR', img: '/media/siegel/114.png', imgRatio: [15, 2] },
    { i: 115, t: 'Land Rover Approved', mobileKey: 'LANDROVER', img: '/media/siegel/115.png', imgRatio: [45, 8] },
    { i: 116, t: 'Alfa Romeo & Jeep SELECTED4U', img: '/media/siegel/116.png', imgRatio: [40, 7] },
    { i: 118, t: 'Hyundai i-Best', mobileKey: 'HYUNDAI_PROMISE', img: '/media/siegel/118.png', imgRatio: [96, 37] },
    { i: 119, t: 'Maserati Certified Pre-Owned', mobileKey: 'MASERATI', img: '/media/siegel/119.png', imgRatio: [24, 7] },
    { i: 120, t: 'Nissan Collection', mobileKey: 'NISSAN', img: '/media/siegel/120.png', imgRatio: [3, 1] },
    { i: 121, t: 'Harley-Davidson Originals', img: '/media/siegel/121.png', imgRatio: [24, 7] },
    { i: 127, t: 'Aston Martin Timeless', mobileKey: 'ASTON_MARTIN', img: '/media/siegel/127.png', imgRatio: [48, 25] },
    { i: 122, t: 'Mercedes-Benz Junge Sterne Transporter', img: '/media/siegel/122.png', imgRatio: [29, 10] },
    { i: 123, t: 'BVfK', img: '/media/siegel/123.png', imgRatio: [70, 31] },
    { i: 124, t: 'Dekra Siegel', img: '/media/siegel/124.png', imgRatio: [1, 1] },
    { i: 125, t: 'PEUGEOT Qualitäts-Gebrauchtwagen', img: '/media/siegel/125.png', imgRatio: [24, 5] },
    { i: 126, t: 'Ford A1 Euro-Garantie', img: '/media/siegel/126.png', imgRatio: [50, 49] },
    { i: 127, t: 'Kia Zertifizierte Gebrauchtwagen', mobileKey: 'KIA_ZERTIFIZIERTE_GEBRAUCHTWAGEN' },
    { i: 128, t: 'Selezione Lamborghini Certified Pre-Owned', mobileKey: 'LAMBORGHINI' },
    { i: 128, t: 'VW PKW Zertifizierte Gebrauchtwagen', mobileKey: 'VW' },
    { i: 128, t: 'VW NFZ Zertifizierte Gebrauchtwagen', mobileKey: 'VW_TRADEPORT' },
];
export default sIntSiegel;
