import axios, { sUrl } from '.';
import { ConfigObject } from '../../_configs/types';
import { Car, CarList, Filter, FilterService } from '../../_types/Car';
import { checkUndefinedNullOrEmpty } from '../../utils/tools';
import type { DBCarSendForm } from '../types/sendForm';

export function getCar(settings: ConfigObject, id: Car<true>['id'], signal: AbortController['signal']) {
    const dataPathString = [];
    const idGroup = 13; /* settings?.general?.idFirma */
    const dataPath: FilterService = {
        id,
        idGroup,
        onlyFacetts: 'false',
        raw: 'false',
        lang: settings?.general?.language,
    };

    for (let i = 0; i < Object.keys(dataPath).length; i++) {
        const key = Object.keys(dataPath)[i];
        const value = dataPath[key];
        if (!checkUndefinedNullOrEmpty(value) && value !== -100 && value !== '-100') {
            dataPathString.push(key + '=' + value);
        }
    }

    return axios<Car<false>>({
        title: 'Abrufen des Fahrzeugs',
        noLogging: false,
        method: 'get',
        url: `${sUrl}/cars/?${dataPathString.join('&')}`,
        signal,
    });
}

export function getCarList(settings: ConfigObject, suche: Record<string, any>, signal: AbortController['signal']) {
    const dataPathString = [];
    const idGroup = 13; /* settings?.general?.idFirma */
    const dataPath: FilterService = {
        ...suche,
        idGroup,
        onlyFacetts: 'false',
        raw: 'false',
        lang: settings?.general?.language,
    };

    if (!checkUndefinedNullOrEmpty(suche?.page)) {
        dataPath.page = (suche?.page - 1)?.toString();
        dataPath.pageSize = (suche?.pageSize)?.toString();
    }

    if (settings?.general?.debug) {
        console.log(`${module} - getCarList - dataPath`, dataPath);
    }

    const searchForOcto: Filter = {};

    for (let i = 0; i < Object.keys(dataPath).length; i++) {
        const key = Object.keys(dataPath)[i];
        const value = dataPath[key];
        if (!checkUndefinedNullOrEmpty(value) && value !== -100 && value !== '-100') {
            dataPathString.push(key + '=' + value);
            searchForOcto[key] = value;
        }
    }

    if (settings?.general?.debug) {
        console.log(`${module} - getCarList - searchForOcto`, searchForOcto);
        console.log(`${module} - getCarList - dataPathString`, dataPathString);
    }

    return {
        search: searchForOcto,
        // TODO: Prüfen ob true geht oder ob wie vorher war false sein muss
        service: axios<CarList<true>>({
            title: 'Abrufen der Fahrzeugliste',
            noLogging: false,
            method: 'get',
            signal,
            url: `${sUrl}/cars/?${dataPathString.join('&')}`,
            validateStatus: (status) => (status === 200),
        }),
    };
}

export function getAllFacets() {
    const dataPathString = [];
    const idGroup = 13; /* settings?.general?.idFirma */

    const dataPath: FilterService = {
        idGroup,
        onlyFacetts: 'true',
        raw: 'false',
    };

    for (let i = 0; i < Object.keys(dataPath).length; i++) {
        const key = Object.keys(dataPath)[i];
        const value = dataPath[key];
        if (!checkUndefinedNullOrEmpty(value) && value !== -100 && value !== '-100') dataPathString.push(key + '=' + value);
    }

    return axios<CarList<false>>({
        title: 'Abrufen der Facetten',
        noLogging: false,
        method: 'get',
        url: `${sUrl}/cars/?${dataPathString.join('&')}`,
    });
}

export function getCarExposee(idCar: number) {
    return axios<string>({
        title: 'Abrufen des Exposee',
        noLogging: false,
        method: 'get',
        url: `${sUrl}/cars/exposee?idCar=${idCar}`,
        responseType: 'blob',
        timeout: 60000,
    });
}

export function sendForm(data: DBCarSendForm) {
    return axios<undefined>({
        title: 'Senden des Kontaktformulars',
        noLogging: false,
        method: 'post',
        url: `${sUrl}/cars/sendForm`,
        data,
    });
}