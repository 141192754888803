/* eslint-disable @typescript-eslint/prefer-promise-reject-errors */
import axios, { AxiosError, AxiosInterceptorManager, AxiosRequestConfig, AxiosResponse, HeadersDefaults } from 'axios';
import { checkUndefinedNullOrEmpty } from '../../utils/tools';
import { DEV, LOCALSERVER, SERVLETPORT } from '../../vars';
import { DBLogLevel, DBLogSource } from '../types/log';
import { createLog } from './logs';

export const sUrl = LOCALSERVER ? `http://localhost:${SERVLETPORT}` : DEV ? 'https://srcars-dev.autohaus-digital.de' : 'https://srcars.autohaus-digital.de'

type CustomAxiosRequestConfig<D = any> = AxiosRequestConfig<D> & {
    idFirma?: number,
    noLogging?: boolean,
    title?: string,
};

type CustomAxiosResponse<T = any, D = any> = AxiosResponse<T, D> & {
    config: CustomAxiosRequestConfig<D>,
    file?: File,
};

export type CustomAxiosError = AxiosError<any> & {
    config: CustomAxiosRequestConfig,
    message: string,
    title: string
};

interface CustomAxiosDefaults<D = any> extends Omit<CustomAxiosRequestConfig<D>, 'headers'> {
    headers: HeadersDefaults;
}

type CustomAxios = {
    defaults: CustomAxiosDefaults,
    interceptors: {
        request: AxiosInterceptorManager<CustomAxiosRequestConfig>,
        response: AxiosInterceptorManager<CustomAxiosResponse>,
    },
    getUri(config?: CustomAxiosRequestConfig): string,
    request<T = any, R = CustomAxiosResponse<T>, D = any>(config: CustomAxiosRequestConfig<D>): Promise<R>,
    get<T = any, R = CustomAxiosResponse<T>, D = any>(url: string, config?: CustomAxiosRequestConfig<D>): Promise<R>,
    delete<T = any, R = CustomAxiosResponse<T>, D = any>(url: string, config?: CustomAxiosRequestConfig<D>): Promise<R>,
    head<T = any, R = CustomAxiosResponse<T>, D = any>(url: string, config?: CustomAxiosRequestConfig<D>): Promise<R>,
    options<T = any, R = CustomAxiosResponse<T>, D = any>(url: string, config?: CustomAxiosRequestConfig<D>): Promise<R>,
    post<T = any, R = CustomAxiosResponse<T>, D = any>(url: string, data?: D, config?: CustomAxiosRequestConfig<D>): Promise<R>,
    put<T = any, R = CustomAxiosResponse<T>, D = any>(url: string, data?: D, config?: CustomAxiosRequestConfig<D>): Promise<R>,
    patch<T = any, R = CustomAxiosResponse<T>, D = any>(url: string, data?: D, config?: CustomAxiosRequestConfig<D>): Promise<R>,
    postForm<T = any, R = CustomAxiosResponse<T>, D = any>(url: string, data?: D, config?: CustomAxiosRequestConfig<D>): Promise<R>,
    putForm<T = any, R = CustomAxiosResponse<T>, D = any>(url: string, data?: D, config?: CustomAxiosRequestConfig<D>): Promise<R>,
    patchForm<T = any, R = CustomAxiosResponse<T>, D = any>(url: string, data?: D, config?: CustomAxiosRequestConfig<D>): Promise<R>,
};

export type CustomAxiosPromise<T = any> = Promise<CustomAxiosResponse<T>>;

export type CustomAxiosInstance = CustomAxios & {
    <T = any>(config: CustomAxiosRequestConfig): CustomAxiosPromise<T>,
    <T = any>(url: string, config?: CustomAxiosRequestConfig): CustomAxiosPromise<T>,
};

const axiosInstance: CustomAxiosInstance = axios.create({
    timeout: 15000,
});

axiosInstance.interceptors.response.use(function (serviceResponse: CustomAxiosResponse): Promise<CustomAxiosResponse> {
    if (serviceResponse.data instanceof Blob) {
        const contentDisposition = serviceResponse.headers['content-disposition'] || '';
        const filename = contentDisposition.replace('attachment; filename="', '').replace('"', '');
        const file = (new File([serviceResponse.data], filename, { type: serviceResponse.headers['content-type'] }));
        return Promise.resolve({ ...serviceResponse, data: URL.createObjectURL(file), file: file });
    } else {
        return Promise.resolve(serviceResponse);
    }
}, async function (serviceError: CustomAxiosError): Promise<CustomAxiosError> {

    if (serviceError.code === 'ERR_CANCELED') return Promise.reject({ aborted: true, message: 'Request aborted' });

    const newServiceError = Object.assign({}, serviceError);
    if (newServiceError?.code === 'ERR_CANCELED') {
        newServiceError.message = 'Die Anfrage wurde abgebrochen!';
    }
    if (newServiceError?.code === 'ECONNABORTED') {
        newServiceError.message = 'Die Anfrage hat zu lange gebraucht, um eine Antwort zu senden! Bitte versuchen Sie es später erneut!';
    }

    if (!checkUndefinedNullOrEmpty(newServiceError?.response?.data)) {
        if (newServiceError?.response?.data instanceof Blob) {
            const blobText = await newServiceError?.response?.data?.text();
            newServiceError.response.data = blobText;
        } else if (typeof newServiceError?.response?.data === 'string') {
            newServiceError.message = newServiceError?.response.data;
        } else if (!checkUndefinedNullOrEmpty(newServiceError?.response?.data?.message)) {
            newServiceError.message = newServiceError?.response?.data?.message;
        } else {
            newServiceError.message = JSON.stringify(newServiceError?.response?.data);
        }
    }
    if (newServiceError?.code !== 'ERR_CANCELED') {
        console.error(newServiceError);
    }
    if (newServiceError?.code !== 'ERR_CANCELED' && !newServiceError?.config?.noLogging) {
        await createLog({
            idProject: newServiceError?.config?.idFirma,
            level: newServiceError?.response?.status === 204 || newServiceError?.response?.status === 401 || newServiceError?.response?.status === 403 || newServiceError?.response?.status === 404 ? DBLogLevel.Warning : DBLogLevel.Error,
            message: newServiceError?.message,
            raw: {
                code: newServiceError?.code,
                config: {
                    data: newServiceError?.config?.data,
                    headers: newServiceError?.config?.headers,
                    method: newServiceError?.config?.method,
                    timeout: newServiceError?.config?.timeout,
                    url: newServiceError?.config?.url,
                },
                message: newServiceError?.message,
                name: newServiceError?.name,
                response: {
                    data: newServiceError?.response?.data,
                    headers: newServiceError?.response?.headers,
                    status: newServiceError?.response?.status,
                    statusText: newServiceError?.response?.statusText,
                },
            },
            origin: DBLogSource.HPT,
            title: newServiceError?.title || newServiceError?.name || newServiceError?.code,
        });
    }

    return Promise.reject(newServiceError);
});

export default axiosInstance;
