/**
 * @file Handles all configurations for the homepage tools.
 * @author Arne Rief
 */

import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configCaravanCenterNord: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 54787,
        enable: true,
        hideVerfugbarLabels: true,
        projectName: 'Caravan Center Nord',
        searchPath: '/fahrzeugsuche',
        showroooom: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
    },
    fahrzeugsuche: {
       ...configDefault.fahrzeugsuche,
        filter: {
            sidebar: ['tags', 'hersteller', 'modell', 'aufbau', 'typ', 'kategorie', 'km', 'preis', 'ez', 'motor', 'getriebe', 'aus'], // TODO changed 'aufbauten' to 'aufbau'
            top: ['[SM]tags', '[SM]tags', '[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]kategorie', 'aufbau', 'motor', 'getriebe', 'km', 'preis', 'ez', 'aus'], // TODO changed 'aufbauten' to 'aufbau'
        },
        hideProbefahrt: true,
        tradeInLink: '/inzahlungnahme',
    },
    style: {
        ...configDefault.style,
        fontSize: 16,
        fontFamily: '"__Nunito_Sans_b2a0d2", "Helvetica", "Arial", sans-serif',
        primaryColor: '#89ad84',
        secondaryColor: '#3a4c59',
    },
};

export default configCaravanCenterNord;
